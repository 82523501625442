import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, VSection } from "components/common"
import Footer from "components/navigation/Footer"
import Nav from "components/navigation/Nav"

import {
  richTextBody,
  useContentfulComponent,
  useContentfulSection,
  useMeta,
} from "utils"
import MobileNav from "src/components/navigation/mobile/MobileNav"

const PPPage = ({ data: { pp } }) => {
  const {
    pp_content: { title, components },
  } = useContentfulSection(pp.sections)

  const meta = useMeta(pp)

  const { privacy_policy_body } = useContentfulComponent(components)

  return (
    <Layout>
      <Seo {...meta} />
      <Nav />
      <MobileNav />

      <Wrap>
        <VSection
          className="pp_content"
          titleAs="h1"
          align="left"
          title={title}
          subtitle={privacy_policy_body.body}
        />
        <Footer />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div`
  position: relative;
  z-index: 1;
  --section-padding-top: var(--sp-nav);
  /* --section-mobile-padding-bottom: 300px;
  --section-padding-bottom: 350px; */
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-background-color: none;
  background: var(--layout_gradient);

  #footer {
    display: none;
  }

  .pp_content {
    ${richTextBody};

    .title {
      margin-bottom: var(--tg-grid-gap);
    }

    strong {
      color: var(--white);
    }

    h4 {
      margin-bottom: 0;
    }
  }
`

export const query = graphql`
  {
    pp: contentfulPage(pageId: { eq: "privacy_policy" }) {
      ...Page
    }
  }
`

export default PPPage
